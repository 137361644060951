import React, { useState, useEffect } from 'react'
import { DatePicker, Spin, message, Modal } from 'antd'
import styles from './index.module.scss'
import { company } from '@/api'
import formatMonth from '@/utils/formatMonth'
import moment from 'moment'

function Gold () {

  const [gold, setGold] = useState({})
  const [yearMonth, setYearMonth] = useState(formatMonth(+new Date()))
  const [goldLoading, setGoldLoading] = useState(true)

  useEffect(() => {

    getGoldInfo()
  }, [])

  const submitRank = () => {

    if (!(rank.dayStepTarget)) return message.error('数据不完整，请检查')

    Modal.confirm({
      title: '提示',
      content: '确定修改吗？',
      centered: true,
      onOk: () => {

        editRank()
      }
    })
  }

  const getGoldInfo = async () => {

    try {

      setGoldLoading(true)

      const { state, data } = await company.getGoldInfo({
        yearMonth,
      })

      if (!state) return

      setGold(data)
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setGoldLoading(false)
    }
  }

  const monthChange = e => {

    if (!e) return

    setYearMonth(formatMonth(e._d))
  }

  return (
    <div className={ styles.page }>

      <div className={ styles.nav }>
        <DatePicker picker="month" size="large" onChange={ monthChange } value={ moment(new Date(yearMonth)) } style={{ margin: '0 14px' }} />
        <div className={ `${styles.nav__btn} center` } onClick={ getGoldInfo }>查找</div>
      </div>

      <Spin spinning={ goldLoading }>
        <div className={ styles.gold }>
          <div className={ styles.gold__item }>
            <span className={ styles.gold__title }>本月发放积分</span>
            <span className={ styles.gold__num }>{ gold.provideGold }</span>
          </div>
          <div className={ styles.gold__item }>
            <span className={ styles.gold__title }>本月兑换积分</span>
            <span className={ styles.gold__num }>{ gold.convertGold } </span>
          </div>
          <div className={ styles.gold__item }>
            <span className={ styles.gold__title }>员工未兑换积分</span>
            <span className={ styles.gold__num }>{ gold.empUnConvertGold }</span>
          </div>
          <div className={ styles.gold__item }>
            <span className={ styles.gold__title }>人均未兑换积分</span>
            <span className={ styles.gold__num }>{ gold.empAvgUnConvertGold }</span>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default Gold
