import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import styles from './index.module.scss'
import { company } from '@/api'

function Bmi () {

  const [listData, setListData] = useState([])
  const [listLoading, setListLoading] = useState(true)

  const listColumns = [
    {
      title: '部门',
      dataIndex: 'deptName',
      width: 100,
    },
    {
      title: '昵称',
      dataIndex: 'nickName',
      width: 100,
    },
    {
      title: '性别',
      dataIndex: 'gender',
      width: 100,
      render: e => (
        <>{ e === 'Male' ? '男' : '女' }</>
      ),
    },
    {
      title: '联系方式',
      dataIndex: 'phoneNumber',
      width: 100,
    },
    {
      title: '最后测量时间',
      dataIndex: 'dateLabel',
      width: 100,
    },
    {
      title: '身高(CM)',
      dataIndex: 'bodyHeight',
      width: 100,
    },
    {
      title: '体重(KG)',
      dataIndex: 'bodyWeight',
      width: 100,
    },
    {
      title: 'BMI',
      dataIndex: 'bmi',
      width: 100,
      render: e => (
        <>{ Number(e).toFixed(2) }</>
      ),
    },
    {
      title: '身体状况',
      dataIndex: 'bmiLabel',
      width: 100,
      render: (e, { bmi }) => (
        <span className={ styles.bmiLabel } style={{ color: getColor(bmi) }}>{ e }</span>
      ),
    },
  ]

  useEffect(() => {

    getBmiReport()
  }, [])

  const getBmiReport = async () => {

    try {

      const { state, data } = await company.getBmiReport()

      if (!state) return

      setListData(data.items)
      setListLoading(false)
    } catch (error) {

      console.error('~~error~~', error)
    }
  }

  const getColor = bmi => {

    if (bmi >= 0 && bmi < 18.5) return '#57bbd6'

    if (bmi >= 18.5 && bmi < 24) return '#72c247'

    if (bmi >= 24 && bmi < 27) return '#f2b843'

    if (bmi >= 27 && bmi < 30) return '#ee9538'

    if (bmi >= 30) return '#d65d2a'
  }

  return (
    <div className={ styles.page }>
      <Table
        bordered
        className="fixedWidthTable"
        scroll={{ x: '1200px', y: `calc(100vh - 300px)` }}
        rowKey={ e => e.phoneNumber }
        loading={ listLoading }
        columns={ listColumns }
        dataSource={ listData }
        pagination={ false }
      />
    </div>
  )
}

export default Bmi
