import React, { useState, useEffect } from 'react'
import { Input, InputNumber, Spin, message, Modal, Form, TimePicker, Switch, Button, Tabs, Table } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import styles from './index.module.scss'
import { company } from '@/api'
import cloneDeep from 'lodash.clonedeep'
import moment from 'moment'

const { TabPane } = Tabs

const formItemLayout = {
  labelCol: { span: 5, offset: 2, },
  wrapperCol: { span: 14, },
  labelAlign: 'left',
}

function Setting () {

  const [ form ] = Form.useForm()
  const [type, setType] = useState('')
  const [bmiLoading, setBmiLoading] = useState(true)
  const [activeKey, setActiveKey] = useState('')
  const [menu, setMenu] = useState([])

  const [basic, setBasic] = useState({
    enabled: true,
    dayStepLimit: '',
    inviteReward: '',
    newuserGoldReward: '',
    newuserStepReward: '',
    step: '',
  })
  const [basicLoading, setBasicLoading] = useState(true)

  const [rank, setRank] = useState({
    enabled: true,
    dayStepTarget: '',
    rankingAwardGold: [
      '',
      '',
      '',
    ],
  })
  const [rankLoading, setRankLoading] = useState(true)

  const [bonus, setBonus] = useState([
    {
      addn: '',
      endTimeHM: '00:00:00',
      startTimeHM: '00:00:00',
    },
  ])
  const [bonusEnabled, setBonusEnabled] = useState(false)
  const [bonusLoading, setBonusLoading] = useState(true)

  const [activity, setActivity] = useState([])
  const [activityEnabled, setActivityEnabled] = useState(false)
  const [currentActivity, setCurrentActivity] = useState([])
  const [activityLoading, setActivityLoading] = useState(true)

  useEffect(() => {

    getSetingMenu()
  }, [])

  const getSetingMenu = async () => {

    try {

      const { state, data } = await company.getSetingMenu()

      if (!state) return

      setMenu(data.item)
      setActiveKey(data.item[0].menuId)

      const list = data.item.map(item => item.menuId)

      if (list.includes('gold')) {

        getBasicSetting()
        getRankSetting()
        getBonusSetting()
      }

      if (list.includes('bmi')) {

        getBmiSetting()
      }

      if (list.includes('runline')) {

        getActivityList()
      }
    } catch (error) {

      console.error('~~error~~', error)
    }
  }

  const getActivityList = async () => {

    try {

      setActivityLoading(true)

      const { state, data } = await company.getActivityList()

      if (!state) return

      setActivity(data.items)
      setActivityEnabled(data.enabled)
      setCurrentActivity(data.items.filter(item => item.enabled).map(item => item.activityTemplateId))
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setActivityLoading(false)
    }
  }

  const changeTab = e => {

    if (activeKey === e) return

    setActiveKey(e)
  }

  const addBonus = () => {

    setBonus([
      ...bonus,
      {
        addn: '',
        endTimeHM: '00:00:00',
        startTimeHM: '00:00:00',
      },
    ])
  }

  const removeBonus = index => {

    setBonus(bonus.filter((_, idx) => idx !== index))
  }

  const bonusChange = (e, index) => {

    setBonus(bonus.map((item, idx) => {

      if (idx === index) item.addn = e.target.value

      return item
    }))
  }

  const timeChange = (value, name, index) => {

    setBonus(bonus.map((item, idx) => {

      if (idx === index) item[name] = value.format('LT')

      return item
    }))
  }

  const getBonusSetting = async () => {

    try {

      setBonusLoading(true)

      const { state, data } = await company.getBonusSetting()

      if (!state) return

      setBonusEnabled(data.enabled)

      if (data.items.length > 0) {

        setBonus(data.items)
      }
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setBonusLoading(false)
    }
  }

  const getRankSetting = async () => {

    try {

      setRankLoading(true)

      const { state, data } = await company.getRankSetting()

      if (!state) return

      setRank(data)
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setRankLoading(false)
    }
  }

  const rankChange = (value, name, index) => {

    if (name === 'rankingAwardGold') {

      const clone = cloneDeep(rank)

      clone.rankingAwardGold[index] = value

      setRank(clone)
    } else {

      setRank({
        ...rank,
        [name]: value,
      })
    }
  }

  const getBasicSetting = async () => {

    try {

      setBasicLoading(true)

      const { state, data } = await company.getBasicSetting()

      if (!state) return

      setBasic(data)
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setBasicLoading(false)
    }
  }

  const basicChange = (value, name) => {

    setBasic({
      ...basic,
      [name]: name === 'messageTip' ? value.target.value : value,
    })
  }

  const submitBasic = () => {

    if (!(basic.step && basic.dayStepLimit && basic.inviteReward && basic.newuserStepReward && basic.newuserStepReward && basic.newuserGoldReward)) return message.error('数据不完整，请检查')

    Modal.confirm({
      title: '提示',
      content: '确定修改吗？',
      centered: true,
      onOk: () => {

        editBasic()
      }
    })
  }

  const submitBonus = () => {

    Modal.confirm({
      title: '提示',
      content: '确定修改吗？',
      centered: true,
      onOk: () => {

        editBonus()
      }
    })
  }

  const submitRank = () => {

    if (!(rank.dayStepTarget)) return message.error('数据不完整，请检查')

    Modal.confirm({
      title: '提示',
      content: '确定修改吗？',
      centered: true,
      onOk: () => {

        editRank()
      }
    })
  }

  const editBonus = async () => {

    try {

      setBonusLoading(true)

      const { state } = await company.editBonusSetting({
        enabled: bonusEnabled,
        items: bonus
      })

      if (!state) return

      getBonusSetting()

      message.success('修改成功')
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setBonusLoading(false)
    }
  }

  const editBasic = async () => {

    try {

      setBasicLoading(true)

      const { state } = await company.editBasicSetting({
        ...basic,
      })

      if (!state) return

      getBasicSetting()

      message.success('修改成功')
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setBasicLoading(false)
    }
  }

  const editRank = async () => {

    try {

      setRankLoading(true)

      const { state } = await company.editRankSetting({
        ...rank,
      })

      if (!state) return

      getRankSetting()

      message.success('修改成功')
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setRankLoading(false)
    }
  }

  const onSubmit = values => {

    if (type === 'edit') {

      editBmiSetting(values)
    } else {

      executeBmiSetting(values)
    }
  }

  const editBmiSetting = values => {

    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: '确定保存吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {

        try {

          const { state, data } = await company.editBmiSetting({
            ...values,
          })

          if (!state) return

          message.success('保存成功')

          getBmiSetting()
        } catch (error) {

          console.error('~~error~~', error)
        }
      }
    })
  }

  const executeBmiSetting = values => {

    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: '确定执行吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {

        try {

          const { state, data } = await company.executeBmiSetting({
            ...values,
          })

          if (!state) return

          message.success('执行成功')

          getBmiSetting()
        } catch (error) {

          console.error('~~error~~', error)
        }
      }
    })
  }

  const getBmiSetting = async () => {

    try {

      setBmiLoading(true)

      const { state, data } = await company.getBmiSetting()

      if (!state) return

      form.setFieldsValue({
        ...data,
      })
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setBmiLoading(false)
    }
  }

  const render = menu => {

    if (menu === 'gold') {

      return renderGold()
    } else if (menu === 'bmi') {

      return renderBmi()
    } else if (menu === 'runline') {

      return renderRunline()
    }
  }

  const renderGold = () => {

    return (
      <>
        <div className={ styles.main }>
          <div className={ styles.main__title }>基本设置</div>

          <Spin spinning={ basicLoading }>

            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>启用</div>
              <div className={ styles.main__right }>
                <Switch disabled checked={ basic.enabled } onChange={ e => basicChange(e, 'enabled') } />
              </div>
            </div>

            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>首页滚动提示</div>
              <div className={ styles.main__right }>
                <Input value={ basic.messageTip } onChange={ e => basicChange(e, 'messageTip') } size="large" className={ styles.main__input } style={{ width: '100%' }} />
              </div>
            </div>

            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>兑换一个积分所需步数</div>
              <div className={ styles.main__right }>
                <InputNumber value={ basic.step } onChange={ e => basicChange(e, 'step') } size="large" className={ styles.main__input } />
              </div>
            </div>

            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>每日步数收集上线</div>
              <div className={ styles.main__right }>
                <InputNumber value={ basic.dayStepLimit } onChange={ e => basicChange(e, 'dayStepLimit') } size="large" className={ styles.main__input } />
              </div>
            </div>

            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>邀请送步数</div>
              <div className={ styles.main__right }>
                <InputNumber value={ basic.inviteReward } onChange={ e => basicChange(e, 'inviteReward') } size="large" className={ styles.main__input } />
              </div>
            </div>

            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>新加入送步数</div>
              <div className={ styles.main__right }>
                <InputNumber value={ basic.newuserStepReward } onChange={ e => basicChange(e, 'newuserStepReward') } size="large" className={ styles.main__input } />
              </div>
            </div>

            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>新加入送积分</div>
              <div className={ styles.main__right }>
                <InputNumber value={ basic.newuserGoldReward } onChange={ e => basicChange(e, 'newuserGoldReward') } size="large" className={ styles.main__input } />
              </div>
            </div>
          </Spin>

          <div className={ `${styles.main__btn} center` } onClick={ submitBasic }>保存</div>
        </div>

        <div className={ styles.main }>
          <div className={ styles.main__title }>排行榜</div>

          <Spin spinning={ rankLoading }>
            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>启用</div>
              <div className={ styles.main__right }>
                <Switch checked={ rank.enabled } onChange={ e => rankChange(e, 'enabled') } />
              </div>
            </div>

            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>排行榜达人步数</div>
              <div className={ styles.main__right }>
                <InputNumber value={ rank.dayStepTarget } onChange={ e => rankChange(e, 'dayStepTarget') } size="large" className={ styles.main__input } />
              </div>
            </div>

            <div className={ styles.main__wrap }>
              <div className={ styles.main__left }>达人榜积分奖励（第一/第二/第三）</div>
              <div className={ styles.main__right }>
                <InputNumber value={ rank.rankingAwardGold[0] } onChange={ e => rankChange(e, 'rankingAwardGold', 0) } size="large" className={ styles.main__input } />
                <InputNumber value={ rank.rankingAwardGold[1] } onChange={ e => rankChange(e, 'rankingAwardGold', 1) } size="large" className={ styles.main__input } style={{ margin: '0 20px' }} />
                <InputNumber value={ rank.rankingAwardGold[2] } onChange={ e => rankChange(e, 'rankingAwardGold', 2) } size="large" className={ styles.main__input } />
              </div>
            </div>
          </Spin>

          <div className={ `${styles.main__btn} center` } onClick={ submitRank }>保存</div>
        </div>

        <div className={ styles.main }>
          <div className={ styles.main__title }>步数加成</div>

          <Spin spinning={ bonusLoading }>
            <div className={ styles.main__wrap }>
              <div className={ styles.main__left } style={{ width: '30%' }}>启用</div>
              <div className={ styles.main__right } style={{ width: '70%' }}>
                <Switch checked={ bonusEnabled } onChange={ e => setBonusEnabled(e) } />
              </div>
            </div>
            {
              bonus.map(({ addn, endTimeHM, startTimeHM }, index) => {

                return (
                  <div className={ styles.main__wrap } key={ index }>
                    <div className={ styles.main__left }>步数加成时间段</div>
                    <div className={ styles.main__right }>
                      <TimePicker value={ moment(startTimeHM, 'HH:mm') } format="HH:mm" onChange={ e => timeChange(e, 'startTimeHM', index) } size="large" />
                      <span> 到 </span>
                      <TimePicker value={ moment(endTimeHM, 'HH:mm') } format="HH:mm" onChange={ e => timeChange(e, 'endTimeHM', index) } size="large" />
                      <span> 奖励 </span>
                      <Input value={ addn } onChange={ e => bonusChange(e, index) } addonAfter="%" className={ styles.main__input } size="large" />
                      { bonus.length > 1 && <Button size="default" type="danger" style={{ margin: '0 0 0 10px' }} onClick={ () => removeBonus(index) }>删除</Button> }
                      { bonus.length === index + 1 && <Button size="default" type="primary" style={{ margin: '0 0 0 10px' }} onClick={ addBonus }>新增</Button> }
                    </div>
                  </div>
                )
              })
            }
          </Spin>

          <div className={ `${styles.main__btn} center` } onClick={ submitBonus }>保存</div>
        </div>
      </>
    )
  }

  const renderBmi = () => {

    return (
      <Spin spinning={ bmiLoading }>
        <div className={ styles.bmi }>
          <div className={ styles.bmi__title }>BMI：小程序录入身高体｜体重</div>

          <Form form={ form } { ...formItemLayout } onSubmit={ onSubmit } onFinish={ onSubmit }>
            <Form.Item valuePropName="checked" label="启用" name="enabled">
              <Switch />
            </Form.Item>

            <Form.Item label="上次执行时间" name="previousExecuteTimeLabel">
              <Input size="large" disabled style={{ width: 250 }} />
            </Form.Item>

            <Form.Item label="下次执行时间" name="nextExecuteTimeLabel">
              <Input size="large" disabled style={{ width: 250 }} />
            </Form.Item>

            <Form.Item label="自动(设置天数)" name="days" rules={[{required: true, message: '请输入天数'}]}>
              <Input type="number" maxLength={ 3 } addonAfter="天" size="large" placeholder="请输入天数" style={{ width: 150 }} />
            </Form.Item>

            <Form.Item label="温馨提醒" name="tipTitle" rules={[{required: true, message: '请输入温馨提醒'}]}>
              <Input size="large" placeholder="请输入温馨提醒" style={{ width: '100%' }} />
            </Form.Item>
          </Form>

          <div>
            <div className={ styles.bmi__btn } onClick={ () => {
              setType('edit')
              form.submit()
            } }>保存</div>
            <div className={ styles.bmi__btn } onClick={ () => {
              setType('execute')
              form.submit()
            } }>立即执行</div>
          </div>
        </div>
      </Spin>
    )
  }

  const renderRunline = () => {

    return (
      <Spin spinning={ activityLoading }>
        <div className={ styles.runline }>

          <div className={ styles.main__wrap } style={{ marginBottom: '20px' }}>
            <div className={ styles.main__left }>启用</div>
            <div className={ styles.main__right }>
              <Switch checked={ activityEnabled } onChange={ e => setActivityEnabled(e) } />
            </div>
          </div>

          <Table
            rowKey={ e => e.activityTemplateId }
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: currentActivity,
              onChange: (selectedRowKeys) => {

                setCurrentActivity(selectedRowKeys)
              },
            }}
            columns={[
              {
                title: '活动名称',
                dataIndex: 'activityTitle',
              },
            ]}
            dataSource={ activity }
            pagination={ false }
          />
          <div className={ styles.runline__btn } onClick={ editActivity }>保存</div>
        </div>
      </Spin>
    )
  }

  const editActivity = () => {

    Modal.confirm({
      title: '提示',
      content: '确定修改吗？',
      centered: true,
      onOk: async () => {

        try {

          setActivityLoading(true)

          const { state } = await company.editActivity({
            enabled: activityEnabled,
            items: activity.map(item => {

              return {
                ...item,
                enabled: currentActivity.includes(item.activityTemplateId),
              }
            })
          })

          if (!state) return

          getActivityList()

          message.success('修改成功')
        } catch (error) {

          console.error('~~error~~', error)
        } finally {

          setActivityLoading(false)
        }
      }
    })
  }

  return (
    <div className={ styles.page }>

      <Tabs type="card" activeKey={ activeKey } onChange={ changeTab }>

        {
          menu.map(item => {

            return (
              <TabPane tab={ item.menuName } key={ item.menuId } forceRender={ true }>
                {
                  render(item.menuId)
                }
              </TabPane>
            )
          })
        }

      </Tabs>
    </div>
  )
}

export default Setting
