import React, { useState, useEffect } from 'react'
import { Table, Select, Button, Spin, Modal, Form, Input, InputNumber, Pagination, message } from 'antd'
import styles from './index.module.scss'
import { company } from '@/api'

const formItemLayout = {
  labelCol: { span: 5, offset: 2, },
  wrapperCol: { span: 14, },
  labelAlign: 'left',
}

function Award () {

  const [ form ] = Form.useForm()
  const [listData, setListData] = useState([])
  const [flag, setFlag] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [size, setSize] = useState(50)
  const [listLoading, setListLoading] = useState(false)
  const [visibleAward, setVisibleAward] = useState(!true)
  const [empInfo, setEmpInfo] = useState([])
  const [deptId, setDeptId] = useState('')
  const [nickName, setNickName] = useState('')
  const [userId, setUserId] = useState([])

  const listColumns = [
    {
      title: '昵称',
      dataIndex: 'nickName',
      width: 100,
    },
    {
      title: '所属部门',
      dataIndex: 'deptName',
      width: 100,
    },
    {
      title: '手机号',
      dataIndex: 'phoneNumber',
      width: 100,
    },
    {
      title: '剩余积分',
      dataIndex: 'gold',
      width: 100,
    },
    {
      title: '操作',
      width: 100,
      render (e) {

        return <Button type="primary" onClick={ () => showAward(e.userId) }>加积分</Button>
      }
    },
  ]

  useEffect(() => {

    getEmpInfo()
    getRewardGoldQueryEmpList()
  }, [flag])

  const showAward = userId => {

    setUserId([userId])

    setVisibleAward(true)
  }

  const submit = values => {

    Modal.confirm({
      title: '提示',
      centered: true,
      content: '确认操作吗？',
      onOk: () => {

        rewardGold(values)
      },
    })
  }

  const rewardGold = async ({ desc, gold }) => {

    try {

      const { state, data } = await company.rewardGold({
        desc,
        gold,
        userId,
      })

      if (!state) return

      setVisibleAward(false)

      message.success('操作成功')

      getRewardGoldQueryEmpList()

      form.resetFields()
    } catch (error) {

      console.error('~~error~~', error)
    }
  }

  const getRewardGoldQueryEmpList = async () => {

    try {

      setListLoading(true)

      const { state, data } = await company.getRewardGoldQueryEmpList({
        deptId: deptId || '0',
        firstResult: (page - 1) * size,
        nickName: nickName || '',
        resultSize: size,
      })

      if (!state) return

      setListData(data.items)
      setTotal(+data.pageable.resultCount)
      setSize(+data.pageable.resultSize)
    } catch (error) {

      console.error('~~error~~', error)
    } finally {

      setListLoading(false)
    }
  }

  const getEmpInfo = async () => {

    try {

      const { state, data } = await company.getEmpInfo()

      if (!state) return

      setEmpInfo(data.deptInfo)
    } catch (error) {

      console.error('~~error~~', error)
    }
  }

  return (
    <div className={ styles.page }>
      <div className="searchbar">
        <div className="searchitem">
          <Input.Group compact>
            <Select placeholder="请选择部门" allowClear={ true } value={ deptId } style={{ width: 200 }} onChange={ setDeptId }>
              { empInfo.map(item => <Select.Option key={ item.deptId } value={ item.deptId }> { item.deptName } </Select.Option>) }
            </Select>
            <Input placeholder="请输入员工昵称" style={{ width: 200 }} onChange={ e => setNickName(e.target.value) } />
            <Button className="btn" type="primary" onClick={ getRewardGoldQueryEmpList }>查询</Button>
          </Input.Group>
        </div>
        <div className="searchbtn">
          <Button disabled={ !userId.length } className="btn" type="primary" size="large" onClick={ () => setVisibleAward(true) }>批量加积分</Button>
        </div>
      </div>

      <Table
        bordered
        className="fixedWidthTable"
        scroll={{ x: '1200px', y: `calc(100vh - 300px)` }}
        rowKey={ e => e.userId }
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: userId,
          onChange: selectedRowKeys => {

            setUserId(selectedRowKeys)
          }
        }}
        loading={ listLoading }
        columns={ listColumns }
        dataSource={ listData }
        pagination={ false }
      />

      <div className="pagebar">
        <Pagination
          onChange={ e => {

            setPage(e)
            setFlag(!flag)
          } }
          total={ total }
          showTotal={ total => `共 ${total} 条` }
          pageSize={ size }
          current={ page }
          defaultCurrent={ page }
          showSizeChanger={ false }
        />
      </div>

      <Modal
        visible={ visibleAward }
        title={ `${userId.length > 1 ? '批量' : ''}加积分` }
        onCancel={ () => ( setVisibleAward(false), form.resetFields() ) }
        onOk={ null }
        maskClosable={ false }
        centered
        width="40vw"
        footer={[
          <Button form="addForm" key="save" type="primary" htmlType="submit" size="default">确定</Button>,
          <Button key="cancel" type="default" size="default" onClick={ () => ( setVisibleAward(false), form.resetFields() ) }>取消</Button>,
        ]}
      >
        <Form id="addForm" form={ form } { ...formItemLayout } onFinish={ submit }>
          <Form.Item label="积分" name="gold" rules={[{required: true, message: '请输入积分'}]}>
            <InputNumber size="large" placeholder="请输入积分" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item label="说明" name="desc" rules={[{required: true, message: '请输入说明'}]}>
            <Input.TextArea size="large" placeholder="请输入说明" style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Award
